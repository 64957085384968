input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

label {
  display: inline-block;
  width: 5em;
  padding: 0 1em;
  text-align: right;
}

/* Hide the file input using
opacity */
[type=file] {
    /* position: absolute; */
    filter: alpha(opacity=0);
    /* opacity: 0; */
}
input,
[type=file] + label {
  border: 1px solid rgb(30, 34, 39);
  border-radius: 3px;
  text-align: left;
  padding: 10px;
  width:100%;

  flex-grow: inherit;
  margin: 0;
  left: 0;
  position: relative;
}
